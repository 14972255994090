<template>
  <!--
    A component solely made to test out new functions.
    Don't use this component except for in the TextView.
  -->
  <div>
    <Portlet
      :title="$t('testComponent.test')"
      icon="map-marker"
    >
      <Portlet
        :title="$t('testComponent.gridTest')"
        class="mb-4"
      >
        <Grid
          :ref="kgm_ref"
          :style="{height: 'auto'}"
          :data-items="kgm_computedGridItems(kgm_gridItems)"
          :columns="kgm_responsiveColumns()"
          :filterable="true"
          :filter="kgm_filter"
          :pageable="kgm_pagable"
          :page-size="kgm_take"
          :skip="kgm_skip"
          :take="kgm_take"
          :total="kgm_allGridItems(kgm_gridItems)"
          :sortable="{
            allowUnsort: kgm_allowUnsort,
            mode: kgm_sortMode
          }"
          :sort="kgm_sort"
          cell-render="myTemplate"
          selected-field="selected"
          @rowclick="kgm_gridOnRowClick"
          @filterchange="kgm_gridFilterChange"
          @pagechange="kgm_gridPageChange"
          @sortchange="kgm_gridSortChange"
        >
          <template
            slot="myTemplate"
            slot-scope="{props}"
          >
            <td :class="props.className">
              {{ kgm_getNestedValue(props.field, props.dataItem) }} ({{ $t('testComponent.customCell') }})
            </td>
          </template>
        </Grid>
      </Portlet>

      <Portlet
        :title="$t('testComponent.simulateErrorCodes')"
        icon="map-marker"
        class="mb-4"
      >
        <div class="btn-group">
          <button
            class="btn btn-sm btn-primary"
            @click="get400Error"
          >
            {{ $t('testComponent.errorBadRequest') }}
          </button>
          <button
            class="btn btn-sm btn-primary"
            @click="get401Error"
          >
            {{ $t('testComponent.errorUnauthorised') }}
          </button>
          <button
            class="btn btn-sm btn-primary"
            @click="get403Error"
          >
            {{ $t('testComponent.error403NotFound') }}
          </button>
          <button
            class="btn btn-sm btn-primary"
            @click="get404Error"
          >
            {{ $t('testComponent.error404NotFound') }}
          </button>
          <button
            class="btn btn-sm btn-primary"
            @click="get409Error"
          >
            {{ $t('testComponent.errorConflict') }}
          </button>
          <button
            class="btn btn-sm btn-primary"
            @click="get422Error"
          >
            {{ $t('testComponent.errorValidation') }}
          </button>
        </div>
        <div class="btn-group mt-2">
          <button
            class="btn btn-sm btn-primary"
            @click="get500Error"
          >
            {{ $t('testComponent.errorServer') }}
          </button>
          <button
            class="btn btn-sm btn-primary"
            @click="get503Error"
          >
            {{ $t('testComponent.errorServiceUnavailable') }}
          </button>
        </div>
      </Portlet>

      <Portlet
        :title="$t('testComponent.timeout')"
        icon="stopwatch"
        class="mb-4"
      >
        <div class="input-group">
          <div class="input-group-prepend">
            <button
              class="btn btn-primary"
              @click="getTimeout"
            >
              {{ $t('testComponent.timeoutError') }}
            </button>
          </div>
          <input
            v-model="timeout"
            type="number"
            class="form-control"
            aria-describedby="basic-addon1"
          >
        </div>
      </Portlet>

      <Portlet
        :title="$t('testComponent.problemPriority')"
        icon="map-marker"
        class="mb-4"
      >
        <problem-priority priority="0" />&nbsp;-&nbsp;
        <problem-priority priority="1" />&nbsp;-&nbsp;
        <problem-priority priority="2" />&nbsp;-&nbsp;
        <problem-priority priority="3" />&nbsp;-&nbsp;
        <problem-priority priority="4" />&nbsp;-&nbsp;
        <problem-priority priority="5" />&nbsp;-&nbsp;
        <problem-priority priority="6" />
      </Portlet>

      <Portlet
        :title="$t('testComponent.windowsMacCompatibility')"
        icon="map-marker"
        class="mb-4"
      >
        <button
          class="btn btn-primary"
          @click="openNewTab"
        >
          {{ $t('openNewTab') }}
        </button>
      </Portlet>

      <Portlet
        :title="$t('testComponent.linkTest')"
        icon="map-marker"
        class="mb-4"
      >
        <a
          href="/test"
          class="mr-2"
        >{{ $t('htmlNormalLink') }}</a>
        <router-link to="/test">
          <a>{{ $t('vuejsRouterLink') }}</a>
        </router-link>
      </Portlet>

      <Portlet
        :title="$t('testComponent.moment')"
        icon="map-marker"
        class="mb-4"
      >
        <table class="defaultTable">
          <thead>
            <tr>
              <th>{{ $t('originalDate') }}</th>
              <th>{{ $t('convertedDate') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ dateData1 }}</td>
              <td
                v-tooltip="`${ dateTime_dateTime(dateData1) }`"
                data-toggle="tooltip"
                data-placement="left"
              >
                {{ dateTime_dateTime(dateData1) }}
              </td>
            </tr>
            <tr>
              <td>{{ dateData2 }}</td>
              <td
                v-tooltip="`${ dateTime_dateTime(dateData2) }`"
                data-toggle="tooltip"
                data-placement="left"
              >
                {{ dateTime_dateTime(dateData2) }}
              </td>
            </tr>
            <tr>
              <td>{{ dateTime_dateTimeSeconds(moment()) }}</td>
              <td
                v-tooltip="`${ dateTime_dateTimeSeconds(moment()) }`"
                data-toggle="tooltip"
                data-placement="left"
              >
                {{ moment().add(0.8, 'seconds') | dateTime_fromNowWithoutFuture }}
              </td>
            </tr>
          </tbody>
        </table>
        <p class="mt-3">
          {{ $t('testComponent.showsDateConversionSuccess') }}
        </p>
      </Portlet>

      <Portlet
        :title="$t('testComponent.getCutConfigByInstallationId')"
        icon="cut"
        class="mb-4"
      >
        <div class="row">
          <div class="col-12 col-md-6">
            <input
              v-model="cutConfigId"
              class="form-control mb-3"
              type="number"
            >
            <button
              class="btn btn-primary mb-3"
              @click="getCutConfig()"
            >
              {{ $t('testComponent.getCutConfig') }}
            </button>
          </div>
          <div class="col-12 col-md-6">
            <LoadingPlaceholder v-if="loadingCutConfig" />
            <template v-else>
              <pre v-if="cutConfig">{{ cutConfig }}</pre>
              <p v-else>
                {{ $t('noDataAvailable') }}
              </p>
            </template>
          </div>
        </div>
      </Portlet>

      <Portlet
        :title="$t('testComponent.ping')"
        class="mb-4"
      >
        <EndpointPing />
      </Portlet>

      <Portlet
        :title="$t('testComponent.get')"
        class="mb-4"
      >
        <EndpointGet />
      </Portlet>

      <Portlet
        title="CoordinationHub"
      >
        <CoordinationHub />
      </Portlet>
    </Portlet>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { errorMixin } from '@/mixins/errorMixin.js';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: "TestComponent",
  components: {
    ProblemPriority: () => import('@/components/Problem/ProblemPriority.vue'),
    EndpointPing: () => import('@/components/Endpoint/EndpointPing.vue'),
    EndpointGet: () => import('@/components/Endpoint/EndpointGet.vue'),
    CoordinationHub: () => import('@/components/Installation/CoordinationHub.vue')
  },
  mixins: [
    errorMixin,
    dateTimeMixin,
    kendoGridMixin
  ],
  data () {
    return {
      dateData1: '2018-11-02T17:31:16.611517',
      dateData2: '2018-08-14T10:25:30Z',
      message: null,
      cutConfigId: 2,
      cutConfig: {},
      loadingCutConfig: false,
      timeout: 1000,
      kgm_columns: [
        {
          field: 'ProductID',
          filterable: true,
          filter: 'numeric',
          title: 'Product ID',
          width:'150px'
        },
        {
          field: 'ProductName',
          filterable: true,
          filter: 'text',
          title: 'Product Name'
        },
        {
          field: 'InStock',
          filterable: true,
          filter: 'boolean',
          title: 'In Stock'
        },
        {
          field: 'UnitPrice',
          filterable: true,
          filter: 'numeric',
          title: 'Unit Price'
        }
      ]
    }
  },
  mounted () {
    window.$('[data-toggle="tooltip"]').tooltip();
    this.kgm_gridItems = this.createRandomData(500);
  },
  methods: {
    clickTest (event, columnName, props) {
      event.preventDefault();
      alert(`click-test: columnName = ${columnName}; value = ${props.dataItem[columnName]}`);
    },
    createRandomData (count) {
      const productNames = ['Chai', 'Chang', 'Syrup', 'Apple', 'Orange', 'Banana', 'Lemon', 'Pineapple', 'Tea', 'Milk'];
      const unitPrices = [12.5, 10.1, 5.3, 7, 22.53, 16.22, 20, 50, 100, 120]

      return Array(count).fill({}).map((_, idx) => ({
        ProductID: idx + 1,
        ProductName: productNames[Math.floor(Math.random() * productNames.length)],
        UnitPrice: unitPrices[Math.floor(Math.random() * unitPrices.length)],
        InStock: Math.random() >= 0.5
      }));
    },
    getCutConfig () {
      this.loadingCutConfig = true;
      this.axios.get(`/VideoPostProcessing/GetCutConfig?cutConfigId=${ this.cutConfigId }`)
        .then((response) => {
          this.cutConfig = response.data;
        })
        .catch(() => {})
        .finally(() => {
          this.loadingCutConfig = false;
        });
    },
    get400Error () {
      this.axios.get('/Test/Get400Error')
        .then(() => {
        });
    },
    get401Error () {
      this.axios.get('/Test/Get401Error')
        .then(() => {
        });
    },
    get403Error () {
      this.axios.get('/Test/Get403Error')
        .then(() => {})
        .catch(() => {});
    },
    get404Error () {
      this.axios.get('/Test/Get404Error')
        .then(() => {})
        .catch(() => {});
    },
    get409Error () {
      this.axios.get('/Test/Get409Error')
        .then(() => {})
        .catch(() => {});
    },
    get422Error () {
      this.axios.get('/Test/Get422Error')
        .then(() => {
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
    get500Error () {
      this.axios.get('/Test/Get500Error')
        .then(() => {})
        .catch(() => {});
    },
    get503Error () {
      this.axios.get('/Test/Get503Error')
        .then(() => {})
        .catch(() => {});
    },
    getTimeout () {
      this.axios.get('/Test/GetTimeout', { timeout: this.timeout })
        .then(() => {})
        .catch(() => {});
    },
    openNewTab () {
      let a = document.createElement("a");
      a.href = "/test/";
      let evt = document.createEvent("MouseEvents");
      //the tenth parameter of initMouseEvent sets ctrl key

      //Windows
      //evt.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, true, false, false, false, 0, null);
      //Mac
      evt.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, true, 0, null);

      a.dispatchEvent(evt);
      return;
    }
  }
}
</script>